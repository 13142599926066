import * as React from 'react';
import { Box, createStyles, makeStyles, Typography } from '@material-ui/core';
import SEO from '../components/atoms/SEO';
import GenericPage from '../components/layout/CommonLayout/GenericPage';
import SpecialBoxContact from '../components/organisms/TalkToUs/SpecialBoxContact';
import useAnalyticsSessionData from '../hooks/useAnalyticsSessionData';

// ========== STYLES ========== //

const useStyles = makeStyles(() =>
  createStyles({
    link: {
      textDecoration: 'none',
    },
  })
);

export default function Complaints() {
  useAnalyticsSessionData();

  const classes = useStyles();

  return (
    <GenericPage>
      <SEO title="Complaints" />
      <Box>
        <Box
          p="4% 15% 4% 15%"
          width="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          textAlign="left"
        >
          <Box pb={3}>
            <Typography variant="h4">Complaints procedure</Typography>
          </Box>
          <Box>
            <p>
              We are a member of the Property Redress Scheme (PRS) and we aim to
              provide the highest standards of service to all our customers. To
              ensure that your interests are safeguarded, we have the following
              complaints procedure in place to resolve all customer issues or
              concerns as quickly as possible.
            </p>
            <p>
              <strong>Stage One – Customer Services Manager</strong>
            </p>
            <p>
              Complaints should, in the first instance, be directed to the
              Customer Services Manager. Once received, your letter will be
              acknowledged within 3 working days and you will receive a response
              within 10 working days from receipt of your letter.
            </p>
            <p>
              If we are unable to resolve the matter within the 10 working days
              as stated above, we will provide you with reasons why we could not
              meet this time frame and provide you with an estimate of when a
              full response will be received.
            </p>
            <p>
              Email:{' '}
              <a href="mailto:info@buildtorent.io">info@buildtorent.io</a>
            </p>
            <p>
              In the majority of cases we hope that any issues can be resolved
              quickly and amicably to customers&#39; satisfaction at customer
              services level.
            </p>
            <p>
              <br />
              <strong>Stage Two – Director</strong>
            </p>
            <p>
              If, after you have dealt with the Customer Services department,
              you remain dissatisfied you may address your concerns, in writing,
              to the Managing Director. Once received your letter will be
              acknowledged within 3 working days and you will receive a response
              within 10 working days from receipt of your letter.
            </p>
            <p>Email: thanh@buildtorent.io</p>
            <p>
              After our final written response, we may deem the complaint
              closed. If we deem the matter closed then we reserve the right not
              to enter into any further correspondence.
            </p>
            <p>
              <br />
              <strong>Stage Three – The Property Redress Scheme</strong>
            </p>
            <p>
              If you remain unhappy with the response received from us and have
              exhausted our complaints procedure, you can contact the Property
              Redress Scheme to ask them to investigate your complaint. In order
              to take your complaint to The Property Redress Scheme you must
              first have carried out the following:
            </p>
            <ul>
              <li>
                You have waited 8 weeks from the date of your written complaint
                to us for a response; and
              </li>
              <li>
                It is still within one year from the last communication with you
                regarding this complaint
              </li>
            </ul>
          </Box>
        </Box>
        <Box
          p="4% 10% 4% 10%"
          width="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Box pb={3}>
            <Typography variant="h4"> Talk to us</Typography>
          </Box>
          <Box display="flex" justifyContent="center" alignContent="stretch">
            <a className={classes.link} href="tel:02036339779">
              <SpecialBoxContact comment="(+44) 0203 633 9779" />
            </a>
            <SpecialBoxContact comment="Live Chat" />
            <a className={classes.link} href="mailto:info@buildtorent.io">
              <SpecialBoxContact comment="info@buildtorent.io" />
            </a>
          </Box>
        </Box>
      </Box>
    </GenericPage>
  );
}
